<template>
  <mdb-container>
    <Adminheader></Adminheader>
    <section class="mt-3">
      <mdb-row>
        <mdb-col xl="12" class="mb-4">
          <h3>{{name}}</h3>
          <h5 class="blue-text">Date de diffusion: {{eventdate}}</h5>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col xl="4" class="mb-4">
          <mdb-card cascade class="cascading-admin-card">
            <!--Card Data-->
            <div class="admin-up">
              <mdb-icon fab icon="youtube" class="green"></mdb-icon>
              <div class="data">
                <p>STATUS DU WEBINAIRE</p>
                <mdb-spinner small color="blue" v-if="isloading===true" />
                <h6 class="font-weight-bold dark-grey-text" v-else>{{status}}</h6>
              </div>
            </div>
            <mdb-card-body cascade class="text-right">
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col xl="4" class="mb-4">
          <mdb-card cascade class="cascading-admin-card">
            <!--Card Data-->
            <div class="admin-up">
              <mdb-icon icon="user-check" class="blue"></mdb-icon>
              <div class="data">
                <p>NOMBRE D'INSCRIPTIONS</p>
                <mdb-spinner small color="blue" v-if="isloading===true" />
                <h4 class="font-weight-bold dark-grey-text" v-else>{{nbregistrations}}</h4>
              </div>
            </div>
            <mdb-card-body cascade class="text-right">
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col xl="4" class="mb-4">
          <mdb-card cascade class="cascading-admin-card">
            <!--Card Data-->
            <div class="admin-up">
              <mdb-icon far icon="eye" class="orange"></mdb-icon>
              <div class="data">
                <p>NB. GENS CONNECTÉS</p>
                <mdb-spinner small color="blue" v-if="isloading===true" />
                <h4 class="font-weight-bold dark-grey-text" v-else>TOTAL: {{nbtotalviewers}}</h4>
              </div>
            </div>
            <mdb-card-body cascade class="text-right">
              <p>2 dernières minutes: {{nbviewerslast2minutes}}</p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="6" class="mb-4">
          <mdb-card >
            <mdb-card-header color="grey darken-3">Questions du public</mdb-card-header>
            <mdb-card-body>
              <mdb-spinner small color="blue" v-if="isloading===true" />
              <div v-if="questions.length==0 && isloading===false">
                <h2>Aucune question pour l'instant</h2>
              </div>
              <div v-else style="max-height: 500px; overflow-x: scroll;" id="allquestions">
                <mdb-tbl style="width: 100%;">
                  <tr v-for="question in questions" :key="question.id" v-on:dblclick="setQuestionAsImportant(question.id)" :class="{ 'questions': 1===1, 'isimportant': question.isimportant==='1' }">
                    <td style="width: 25%;"><strong>{{question.name}}</strong><br />{{question.addedat}}</td>
                    <td>{{question.question}}</td>
                  </tr>
                </mdb-tbl>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="6" class="mb-4">
          <mdb-card >
            <mdb-card-header color="info-color-dark">Questions mises de côté</mdb-card-header>
            <mdb-card-body>
              <mdb-spinner small color="blue" v-if="isloading===true" />
              <div v-if="importantquestions.length==0 && isloading===false">
                <h4>Aucune question mise de côté pour l'instant</h4>
              </div>
              <div v-else style="max-height: 500px; overflow-x: scroll;" id="allimportantquestions">
                <mdb-tbl style="width: 100%;">
                  <tr v-for="question in importantquestions" :key="question.id">
                    <td style="width: 25%;"><strong>{{question.name}}</strong><br />{{question.addedat}}</td>
                    <td>{{question.question}}</td>
                  </tr>
                </mdb-tbl>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row class="mb-4">
        <mdb-col md="4">
          <mdb-card >
            <mdb-card-header color="unique-color">Évaluations</mdb-card-header>
            <mdb-card-body>
              <mdb-spinner small color="blue" v-if="isloading===true" />
              <mdb-container v-if="status==='BROADCAST_ENDED'">
                <mdb-bar-chart
                  :data="barChartReviewData"
                  :options="barChartReviewOptions"
                  :width="300"
                  :height="200"
                  v-if="barChartReviewData.datasets[0].data.length"
                ></mdb-bar-chart>
                <mdb-btn color="outline-primary" size="sm" @click.native="showcommentspopup = true">Afficher les commentaires</mdb-btn>
              </mdb-container>
              <p v-else>Le graphique d'évaluation sera affiché quand la présentation sera terminée</p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col md="8">
          <mdb-card >
            <mdb-card-header color="orange darken-1">Statistiques d'écoute</mdb-card-header>
            <mdb-card-body>
              <mdb-spinner small color="blue" v-if="isloading===true" />
              <mdb-line-chart
                :data="lineChartViewersData"
                :options="lineChartViewersOptions"
                :width="690"
                :height="300"
                v-if="lineChartViewersData.datasets[0].data.length"
              ></mdb-line-chart>
              <div v-if="lineChartViewersData.datasets[0].data.length">
                Début: {{beginTimestampChartViewers}}
                <br />
                Fin: {{endTimestampChartViewers}}
              </div>
              <h2 v-else>En attente de données</h2>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal size="lg" :show="showcommentspopup" @close="showcommentspopup = false" :scrollable="true">
      <mdb-modal-header>
        <mdb-modal-title>Commentaires</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-tbl striped>
          <mdb-tbl-body>
            <tr v-for="comment in comments" :key="comment.id">
              <td><strong>{{comment.client}}</strong><br /><em>{{comment.clientemail}}</em></td>
              <td>{{comment.comment}}</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="secondary" size="sm" @click.native="showcommentspopup = false">Fermer</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Adminheader from '@/components/Adminheader.vue';
    import Pusher from 'pusher-js';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbSpinner,
        mdbBarChart,
        mdbLineChart,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbTbl,
        mdbTblBody,
    } from 'mdbvue';
    import Auth from '../libs/Auth';
    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Dashboard',
        components: {
            Adminheader,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbIcon,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbSpinner,
            mdbBarChart,
            mdbLineChart,
            mdbBtn,
            mdbModal,
            mdbModalHeader,
            mdbModalTitle,
            mdbModalBody,
            mdbModalFooter,
            mdbTbl,
            mdbTblBody,
        },
        data() {
            return {
                dummy: null,
                isloading: false,
                questions: [],
                importantquestions: [],
                name: '',
                nbregistrations: 0,
                nbtotalviewers: 0,
                nbviewerslast2minutes: 0,
                status: '',
                eventdate: '',
                fetchBasesInfosInterval: null,
                comments: [],
                showcommentspopup: false,
                barChartReviewData: {
                    labels: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                    ],
                    datasets: [
                        {
                            label: 'Nb. Évaluations',
                            data: [0, 0, 0, 0, 0],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255,99,132,1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                },
                barChartReviewOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                barPercentage: 1,
                                gridLines: {
                                    display: true,
                                    color: 'rgba(0, 0, 0, 0.1)',
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    display: true,
                                    color: 'rgba(0, 0, 0, 0.1)',
                                },
                            },
                        ],
                    },
                },
                lineChartViewersData: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Nombres de viewers',
                            backgroundColor: 'rgba(255, 99, 132, 0.1)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 0.7,
                            data: [],
                        },
                    ],
                },
                lineChartViewersOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    display: true,
                                    color: 'rgba(0, 0, 0, 0.1)',
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    display: true,
                                    color: 'rgba(0, 0, 0, 0.1)',
                                },
                            },
                        ],
                    },
                },
                beginTimestampChartViewers: '',
                endTimestampChartViewers: '',
            };
        },
        mounted() {
            Auth.validateIfCanAccess('ADMINISTRATOR', 'Dashboard');
            this.fetchBasesInfos();
            this.fetchQuestions();
            this.fetchAndBuildViewersGraph();
            this.fetchAndBuildReviewsGraph();

            // Update à toutes les 30 secondes
            this.fetchBasesInfosInterval = window.setInterval(this.fetchBasesInfos, 1000 * 30);
            this.fetchViewerGraphInterval = window.setInterval(this.fetchAndBuildViewersGraph, 1000 * 60 * 5);

            // pusher subcribe
            const pusher = new Pusher('49474a35b55061c735e7', { cluster: 'us2' });
            pusher.subscribe('questions');
            pusher.bind('question_added', (data) => {
                if (data.ident === this.$route.params.ident) {
                    this.questions.push({
                        id: data.id,
                        name: data.name,
                        question: data.question,
                        addedat: data.addedat,
                    });
                    window.setTimeout(() => {
                        const elmnt = document.getElementById('allquestions');
                        if (elmnt) {
                            elmnt.scrollTop = elmnt.scrollHeight;
                        }
                    }, 100);
                }
            });
        },
        beforeDestroy() {
            clearInterval(this.fetchBasesInfosInterval);
            clearInterval(this.fetchViewerGraphInterval);
        },
        methods: {
            test() {
                console.log('test');
            },
            fetchBasesInfos() {
                const self = this;
                const api = new Apicall();
                api.getWebinaireBaseStats(this.$route.params.ident).then((response) => {
                    if (response.status === 'OK') {
                        self.isloading = false;
                        self.name = response.data.name;
                        self.nbregistrations = response.data.nbregistrations;
                        self.nbtotalviewers = response.data.nbtotalviewers;
                        self.nbviewerslast2minutes = response.data.nbviewerslast2minutes;
                        self.status = response.data.status.replace('VIDEO_STATE_', '');
                        self.eventdate = response.data.eventdate;
                    } else {
                        self.$router.push({ name: 'Webinairelist' });
                    }
                });
            },
            fetchQuestions() {
                const self = this;
                const api = new Apicall();
                api.getWebinaireQuestions(this.$route.params.ident).then((response) => {
                    self.isloading = false;
                    self.questions = response.data.questions;
                    self.importantquestions = response.data.importantquestions;
                    window.setTimeout(() => {
                        const elmnt = document.getElementById('allquestions');
                        if (elmnt) {
                            elmnt.scrollTop = elmnt.scrollHeight;
                        }
                    }, 250);
                });
            },
            fetchImportantQuestions() {
                const self = this;
                const api = new Apicall();
                api.getWebinaireQuestions(this.$route.params.ident).then((response) => {
                    self.importantquestions = response.data.importantquestions;
                    window.setTimeout(() => {
                        const elmnt = document.getElementById('allimportantquestions');
                        if (elmnt) {
                            elmnt.scrollTop = elmnt.scrollHeight;
                        }
                    }, 250);
                });
            },
            setQuestionAsImportant(theid) {
                let i = 0;
                let newstate = 1;
                for (i; i < this.questions.length; i += 1) {
                    if (this.questions[i].id === theid) {
                        if (this.questions[i].isimportant === '0') {
                            this.questions[i].isimportant = '1';
                        } else {
                            this.questions[i].isimportant = '0';
                            newstate = 0;
                        }
                    }
                }
                const self = this;
                const api = new Apicall();
                api.setQuestionNewState(theid, newstate).then(() => {
                    self.fetchImportantQuestions();
                });
            },
            fetchAndBuildViewersGraph() {
                const self = this;
                const api = new Apicall();
                const labels = [];
                const data = [];
                let i = 0;
                self.lineChartViewersData.datasets[0].data = [];
                api.getViewersGraphInfos(this.$route.params.ident).then((response) => {
                    if (response.status === 'OK') {
                        self.beginTimestampChartViewers = response.data.timeline.start;
                        self.endTimestampChartViewers = response.data.timeline.end;
                        for (i; i < response.data.timeline.timeline.length; i += 1) {
                            labels.push(response.data.timeline.timeline[i].minute);
                            data.push(response.data.timeline.timeline[i].count);
                        }
                        self.lineChartViewersData.labels = labels;
                        self.lineChartViewersData.datasets[0].data = data;
                    } else {
                        self.lineChartViewersData.labels = [];
                        self.lineChartViewersData.datasets[0].data = [];
                    }
                });
            },
            fetchAndBuildReviewsGraph() {
                const self = this;
                const api = new Apicall();
                self.barChartReviewData.datasets[0].data = [];
                api.getReviewsGraphInfos(this.$route.params.ident).then((response) => {
                    self.barChartReviewData.datasets[0].data.push(response.data.ratings[1]);
                    self.barChartReviewData.datasets[0].data.push(response.data.ratings[2]);
                    self.barChartReviewData.datasets[0].data.push(response.data.ratings[3]);
                    self.barChartReviewData.datasets[0].data.push(response.data.ratings[4]);
                    self.barChartReviewData.datasets[0].data.push(response.data.ratings[5]);
                    self.comments = response.data.reviews;
                });
            },
        },
    });
</script>

<style scoped>
  .cascading-admin-card {
    margin-top: 20px; }
  .cascading-admin-card .admin-up {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -20px; }
  .cascading-admin-card .admin-up .fas, .cascading-admin-card .admin-up .far, .cascading-admin-card .admin-up .fab {
    padding: 1.7rem;
    font-size: 2rem;
    color: #fff;
    text-align: left;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
  .cascading-admin-card .admin-up .data {
    float: right;
    margin-top: 2rem;
    text-align: right; }
  .cascading-admin-card .admin-up .data p {
    color: #999999;
    font-size: 12px;
  }
  tr.questions:hover{
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .isimportant {
    background-color: #e8f5e9;
  }
</style>
